import { urlFor } from 'api/cms';
import { useSiteSettings } from 'hooks/use-siteSettings';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { ImageBase } from 'types/sanity';

interface Props {
  title: string;
  description?: string;
  image?: ImageBase;
}

const SEO: FC<Props> = ({ title, description, image }) => {
  const { siteSettings } = useSiteSettings();

  const ogImage = image || siteSettings.image;
  const ogImageUrl = ogImage && urlFor(ogImage).width(2048).height(1170).url();
  const twitterCardImageUrl =
    ogImage && urlFor(ogImage).width(1200).height(628).url();

  let pageTitle: string;
  if (title === siteSettings.title) {
    pageTitle = title;
  } else {
    pageTitle = `${title} | ${siteSettings.title}`;
  }

  const pageDescription = description ?? siteSettings.description;

  return (
    <Head>
      <title>{pageTitle}</title>
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <meta name='theme-color' content='#000000' />
      <meta property='og:title' content={pageTitle} />
      <meta name='description' content={pageDescription} />
      <meta property='og:description' content={pageDescription} />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content={pageDescription}></meta>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@dfes_wa' />
      {ogImage && ogImageUrl && twitterCardImageUrl && (
        <>
          <meta property='og:image' content={ogImageUrl} />
          <meta name='twitter:image' content={twitterCardImageUrl} />
        </>
      )}
    </Head>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default SEO;
