import { rem } from 'polished';
import styled, { css, Theme } from 'styled-components';
interface Props {
  size?: keyof Theme['fontSize']['text'];
  centerText?: boolean;
}

export const textStyles = css`
  display: block;
  margin: 0;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(40)};
  color: ${({ theme }) => theme.components.text.text};
  line-height: 1.3;
`;

export const Text = styled.p<Props>`
  ${({ theme, size = 'medium', centerText = false }) => {
    return css`
      ${textStyles}

      font-size: ${theme.fontSize.text[size]};
      text-align: ${centerText ? 'center' : 'inherit'};
    `;
  }}
`;
