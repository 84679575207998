import styled, { css } from 'styled-components';

export interface SectionProps {
  textAlign?: 'center' | 'left' | 'right';
}

export const Section = styled.section<SectionProps>`
  ${({ textAlign, theme }) => css`
    text-align: ${textAlign};
    background-color: ${theme.components.section.background};
  `}
`;

Section.defaultProps = {
  textAlign: 'left',
};
