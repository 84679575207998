import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import themeDark from 'styles/theme-dark';
import themeLight from 'styles/theme-light';

interface Props {
  to?: 'light' | 'dark';
}

const SwitchTheme: FC<Props> = (props) => {
  let currentTheme;

  if (props.to === 'dark') {
    currentTheme = {
      ...themeDark,
    };
  } else {
    currentTheme = {
      ...themeLight,
    };
  }

  return <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>;
};

SwitchTheme.defaultProps = {
  to: 'light',
};

SwitchTheme.propTypes = {
  to: PropTypes.oneOf(['light', 'dark']),
};

export default SwitchTheme;
