import borderRadius from './border-radius';
import colors from './colors';
import fontSize from './font-size';
import mediaQueries from './media-queries';
import space from './spacing';
import zIndex from './z-index';

export default {
  borderRadius,
  colors: { ...colors },
  components: {
    button: {
      background: {
        default: colors.palette.redDark,
        dark: colors.palette.black,
        secondary: colors.palette.greyLight,
        transparent: 'transparent',
      },
      border: {
        default: colors.palette.redDark,
        dark: colors.palette.white,
        secondary: colors.palette.greyLight,
        transparent: 'transparent',
      },
      text: {
        default: colors.palette.white,
        dark: colors.palette.white,
        secondary: colors.palette.black,
        transparent: colors.palette.black,
      },
    },
    download: {
      text: colors.palette.white,
      background: colors.palette.black,
    },
    text: {
      text: colors.palette.white,
    },
    heading: {
      text: colors.palette.white,
    },
    section: {
      background: colors.palette.black,
    },
  },
  fontSize,
  space,
  mediaQueries,
  zIndex,
};
