import { rem } from 'polished';
import styled from 'styled-components';

interface WrapperProps {
  noPadding?: boolean;
}

export const SectionWrapper = styled.div<WrapperProps>`
  --padding: ${({ noPadding }) => (noPadding ? 0 : rem(32))};

  max-width: calc(640px + (var(--padding) * 2));
  margin: 0 auto;
  padding: var(--padding);

  ${({ theme }) => theme.mediaQueries.medium} {
    --padding: ${({ noPadding }) => (noPadding ? 0 : rem(60))};
  }

  ${({ theme }) => theme.mediaQueries.large} {
    --padding: ${({ noPadding }) => (noPadding ? `0 ${rem(100)}` : rem(100))};
  }
`;
